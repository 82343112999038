/* Let's default this puppy out
-------------------------------------------------------------------------------*/

html, body, body div, span, object, iframe, h1, 
h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address,
 cite, code, del, dfn, em, img, ins, kbd, q, samp, small,
  strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, 
  fieldset, form, label, legend, table, caption, tbody, 
  tfoot, thead, tr, th, td, article, aside, figure, footer, 
  header, menu, nav, section, time, mark, audio, video, details, summary {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-weight: normal;
	vertical-align: baseline;
	background: transparent;
}

article, aside, figure, footer, header, nav, section, details, summary {display: block;}

/* Handle box-sizing while better addressing child elements:
   https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */

*, body,
*:before,
*:after {
	box-sizing: border-box;
}


/* Responsive images and other embedded objects
*/
img, object, embed {max-width: 100%;}
img {display: block;}

/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	 In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:

#map img {
		max-width: none;
}
*/

/* force a vertical scrollbar to prevent a jumpy page */
html {overflow-y: auto;overflow-x: hidden}

/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul {list-style: none;}

blockquote, q {quotes: none;}

blockquote:before,
blockquote:after,
q:before,
q:after {content: ''; content: none;}

a {margin: 0; padding: 0; font-size: 100%; vertical-align: baseline; background: transparent;}

del {text-decoration: line-through;}

abbr[title], dfn[title] {border-bottom: 1px dotted #000; cursor: help;}

/* tables still need cellspacing="0" in the markup */
table {border-collapse: collapse; border-spacing: 0;}
th {font-weight: bold; vertical-align: bottom;}
td {font-weight: normal; vertical-align: top;}

hr {display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0;}

input, select {vertical-align: middle;}

pre {
    white-space: pre; /* CSS2 */
    white-space: pre-wrap; /* CSS 2.1 */
    white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
    word-wrap: break-word; /* IE */
}


select, input, textarea {font: 99% sans-serif;}

table {font-size: inherit; font: 100%;}

small {font-size: 85%;}

strong {font-weight: bold;}

td, td img {vertical-align: top;}

/* Make sure sup and sub don't mess with your line-heights https://gist.github.com/413930 */
sub, sup {font-size: 75%; line-height: 0; position: relative;}
sup {top: -0.5em;}
sub {bottom: -0.25em;}

/* standardize any monospaced elements */
pre, code, kbd, samp {font-family: monospace, sans-serif;}

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {cursor: pointer;}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {margin: 0; padding: 0;}

/* scale images in IE7 more attractively */
.ie7 img {-ms-interpolation-mode: bicubic;}

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/

/* let's clear some floats */
.clearfix:before, .clearfix:after { content: "\0020"; display: block; height: 0; overflow: hidden; }
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }

/* a tag text-decoration hiding */
a{
	text-decoration: none;
}

/* li tag style reseting */
li{
	list-style-type: none;
}

/* all input appearance reseting*/
input, button{
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
	outline: 0;
	border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
}
input[type="radio"] {
	vertical-align: text-bottom;
    -webkit-appearance: radio;
}
input[type="checkbox"] {
	vertical-align: bottom;
    -webkit-appearance: checkbox;
}

.ie7 input[type="checkbox"] {vertical-align: baseline;}
.ie6 input {vertical-align: text-bottom;}

/* number type inupt spinners hiding */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button{
-webkit-appearance: none;
    margin: 0;
}

body {
  margin: 0;
  font-family: 'Montserrat', cursive, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff !important;
  background-color: #121416 !important;
}

*, *::after, *::before {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.banner-container{
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block-start: 4.75rem;
  margin-block-end: 10rem;
  height: 100vh;
  background-image: url('./banner.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}


.banner-container h1 {
  font-size: 64px;
  line-height: 78px;
  font-family: 'Anton';
  background: -webkit-linear-gradient(295.38deg, #FFE5A2 4.89%, #BF841A 50.44%, #FFCD74 95.06%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-block-end: 32px;
}

.banner-container img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
}

.socials{
  position: absolute;
  top: 25px;
  right: 10%;
}

.social-btn{
  width: 65px;
  height: 65px;
}

.social-opensea{
  width: 75px;
  height: 75px;
}

.social-opensea svg {
  margin-block-start: 6px;
}

.social-btn svg {
  width: 100%;
  height: 100%;
}

.btn {
  font-size: 50px;
  line-height: 50px;
}

.btn-primary{
  font-size: 36px !important;
  line-height: 50px !important;
  background: linear-gradient(295.38deg, #FFE5A2 4.89%, #BF841A 50.44%, #FFCD74 95.06%) !important;
  color: #000 !important;
  letter-spacing: 0.265em !important;
  text-transform: uppercase;
  border: none !important;
  border-radius: 5px;
  font-family: 'Anton';
}

.banenr_text, .new-collection-title{
  position: absolute;
  display: flex;
  align-items: center;
  gap: 20px;
  bottom: -80px;
  right: calc(var(--bs-gutter-x) * 0.5);
  font-size: 28px;
  line-height: 50px;
  letter-spacing: 0.03em;
  background: -webkit-linear-gradient(295.38deg, #FFE5A2 4.89%, #BF841A 50.44%, #FFCD74 95.06%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0;
  font-weight: bold;
  }

.banenr_text::after,
.new-collection-title::after{ 
  content: '';
  display: block;
  width: 2px;
  height: 50px;
  background: -webkit-linear-gradient(295.38deg, #FFE5A2 4.89%, #BF841A 50.44%, #FFCD74 95.06%);
}

.new-collection-title{
  text-transform: uppercase;
  position: relative;
  bottom: 0;
  right: 0;
  background: -webkit-linear-gradient(295.38deg, #FFE5A2 4.89%, #BF841A 50.44%, #FFCD74 95.06%);
}

.about-slider,
.about-slider-2{
  height: 340px;
}

.swiper-wrapper
{
  transition-timing-function: linear;
}

.about-slider-item{
  padding: 16px;
  position: relative;
  background-image: url('./slider_angle.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-top: 2px solid;
  border-left: 2px solid;
  border-image: -webkit-linear-gradient(295.38deg, #FFE5A2 4.89%, #BF841A 50.44%, #FFCD74 95.06%);
  -webkit-border-image: -webkit-linear-gradient(295.38deg, #FFE5A2 4.89%, #BF841A 50.44%, #FFCD74 95.06%);
  border-image-slice: 60 30 !important;
}

.about-slider-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.about-slider-2 .about-slider-item img{
  object-position: center
}

.banner_for_new_col,
.banner_for_prev_col{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-height: 434px;
  text-align: center;
}

.banner_for_prev_col {
  justify-content: flex-start;
}

.banner_for_new_col img,
.banner_for_prev_col img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: left;
  z-index: -1;
}

.banner_for_prev_col img {
  left: auto;
  right: 0;
  object-position: right;
}

.banner_for_new_col > div,
.banner_for_prev_col > div{
  width: 50%;
  margin-inline-start: auto;
}

.banner_for_prev_col > div {
  margin-inline-start: 0;
  margin-inline-end: auto;
}

.banner_for_new_col h3,
.banner_for_prev_col h3{
  font-family: 'Montserrat';
font-weight: bold;
font-size: 42px;
line-height: 50px;
letter-spacing: 0.035em;
text-transform: uppercase;
background: linear-gradient(295.38deg, #FFE5A2 4.89%, #BF841A 50.44%, #FFCD74 95.06%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}

.banner_for_new_col p,
.banner_for_prev_col p {
  font-weight: 400;
  font-size: 32px;
  line-height: 50px;
  letter-spacing: 0.285em;
  color: #ccc;
  text-transform: uppercase;
}

.connect-btns h2{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 50px;
  letter-spacing: 0.03em;
  background: linear-gradient(295.38deg, #FFE5A2 4.89%, #BF841A 50.44%, #FFCD74 95.06%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bear-form-counter{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #fff;
  margin-block-start: 30px;
  font-size: 40px;
  gap: 15px;
  color: #fff;
}

.bear-form-counter span{
  width: 40px;
}

.bear-form-counter .claim-btn{
  position: absolute;
  right: 20px;
}

.bear-btn{
  align-self: center;
  font-size: 40px;
  line-height: 1;
  color: #fff
}

.bear-btn[data-pos='minus'] {
  position: relative;
  top: -3px;
}

.vampire-claimers{
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-evenly;
  margin: 32px 0;
}

.claim-btn {
  width: 100px;
  height: 40px;
  font-family: 'Anton', cursive;
  font-size: 20px;
  line-height: 1;
  text-align: center;
  color: #000;
  background: linear-gradient(295.38deg, #FFE5A2 4.89%, #BF841A 50.44%, #FFCD74 95.06%);
  transition: background-color .5s ease, color .5s ease;
}

@media screen and (max-width: 1440px) {
  .banner-container img{
    object-fit: contain;
  }
}

@media screen and (max-width: 1200px) {
  .order-mobile{
    order: -1;
    margin-block-end: 5rem;
  }
  .banner-container img{
    height: 40vh;
  }
  .banner_for_new_col h3, .banner_for_prev_col h3{
    font-size: 30px;
    line-height: 40px;
  }
  .banner_for_new_col p, .banner_for_prev_col p{
    font-size: 18px;
    line-height: 30px;
  }
  .banner-container{
    height: auto;
  }
  .bear-form-counter .claim-btn{
    right: 0;
  }
}

@media screen and (max-width: 567px) {
  .banner-container h1{
    font-size: 44px;
    line-height: 50px;
  }
  .banner-container{
    padding-inline-start: 16px;
    padding-inline-end: 16px;
  }
  .mint-container{
    padding: 0 16px;
  }
  .banner_for_new_col > div, .banner_for_prev_col > div{
    width: 100%;
  }
  .banner_for_new_col img, .banner_for_prev_col img{
    position: relative;
  }
  .banner_for_new_col, .banner_for_prev_col{
    flex-wrap: wrap;
  }
  .bear-form-counter{
    justify-content: flex-start;
  }
}

@media screen and (min-width: 1600px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container{
    max-width: 1520px !important;
  }
}
